import logo from './assets/PiktogramicLogo.png';
import discord from './assets/JoinDiscord.png';

import './App.scss';

function App() {
  return (
    <div className="App">       
      <div className="pikBody">
        <div className='noticeGroup'>
          <div className="logoHolder">
            <img className="logo" src={logo} alt="Piktogramic"/>
          </div>
          <br/>
          <div className='notice'>
            Will Return. <br/> Major Redesign Underway
          </div>
        </div>
      </div>
      <div className="footer"> 
      Piktogramic Ⓒ MerrowHawk Entertainment LLC 2023
        <br/><a target="_blank" rel="noopener noreferrer" href="https://discord.gg/YCmNXG7E4F">Join our Discord! </a>  
      </div> 
      <div className="theater">
        <ul className="seats">
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>          
        </ul>
      </div>

  </div>
  );
}

export default App;
